import { Box, ResponsiveContext } from 'grommet';
import React from 'react';
import Layout from '../components/elements/layout';
import Works from '../components/elements/works';
import Header from '../components/header';
import MainMenu from '../components/menu';

import salon from '../config/data.json';
import cache_posts from '../config/posts.json';
import { getPosts, getSalon } from '../services/service';

const seo = {
    title: 'BE PERFECT MANUCURE | Notre Travail',
    description: 'Notre galerie des résultats chez Be Perfect Manucure. La satisfaction de nos clients est notre priorité'
}

class OurWorks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            salon,
            posts: cache_posts
        }
    }

    componentDidMount = () => {
        getSalon()
            .then(res => {
                this.setState({ salon: res.data }, () => {
                    getPosts().then(res => {
                        this.setState({ posts: res.data })
                    });
                })
            })
    }


    render() {
        return (
            <Layout style={{ justifyContent: "center", alignContent: "center" }}
                title={seo.title}
                description={seo.description}
            >
                <Box width="xlarge" justify="center" align="center" alignSelf="center">
                    <ResponsiveContext.Consumer>
                        {size =>
                            <Box width="xlarge" pad="medium">
                                <Header data={this.state.salon.data} size={size} />
                                <MainMenu />
                                <Box style={{height: 30}}/>
                                <Works posts={this.state.posts} size={size} />
                            </Box>
                        }
                    </ResponsiveContext.Consumer>

                    <Box style={{ height: 200 }} />
                </Box>
            </Layout>
        )
    }
}

export default OurWorks;